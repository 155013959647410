<template>
  <div :class="`login ${userStore.isLoginRequired ? '-required' : ''}`">
    <div class="_bkg">
      <img loading="lazy" fetchpriority="high" src="https://picsum.photos/1980/1440" />
    </div>
    <div class="_container">
      <div :class="`${isError ? '-error' : ''}`">
        <div class="_logo" v-if="__projectConfig.assets?.logo" v-html="__projectConfig.assets.logo"></div>
        <div v-if="!userStore.isLogged" class="_form">
          <h3 class="_title">
            {{ $t('login.title') }}
          </h3>
          <input ref="$password" type="password" :class="`form-input ${isError ? '-invalid' : ''}`" v-model="password"
            :placeholder="$t('login.password')" @keypress.enter="(e) => {
                login()
              }
              " />
          <input type="button" :class="`button ${isError ? '-error' : ''}`" :value="$t('login.login')" @click="login" />
        </div>
        <div v-else class="_about">
          <input type="button" class="button" :value="$t('login.logout')" @click="userStore.logout" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { __hotkey } from '@lotsof/sugar/keyboard';
import { onMounted, useTemplateRef } from 'vue';
import __projectConfig from '~/project.config.js';
import './login.css';

const userStore = useUserStore()

// force login for preview mode
if (userStore.isLoginRequired) {
  document.body.classList.add('-login')
}

__hotkey('esc', () => {
  if (userStore.isLoginRequired) {
    return
  }
  document.body.classList.remove('-login')
})
__hotkey('cmd+shift+p', () => {
  document.body.classList.toggle('-login')
})

let isError = ref(false)
const $password = useTemplateRef('$password')
let password: string

onMounted(() => {
  // @ts-ignore
  $password?.value?.focus()
})

async function login() {
  const response = await userStore.login(password)
  if (!response.success) {
    isError.value = true
  } else {
    isError.value = false
  }
}
</script>
