export type TWrapIntoIframeSettings = {
  exclude: HTMLElement[] | string[]
}

export default function wrapIntoIframe(): HTMLIFrameElement {
  // create the iframe
  const $iframe = document.createElement('iframe')
  $iframe.classList.add('admin-toolbar-iframe')
  $iframe.setAttribute('nonce', 'admin-toolbar')
  document.body.appendChild($iframe)

  // copy the document into the iframe
  $iframe?.contentWindow?.document.open()
  $iframe?.contentWindow?.document.write(document.documentElement.outerHTML)
  $iframe?.contentWindow?.document.close()

  // remove the admin toolbar from the iframe
  $iframe.contentWindow?.document
    ?.querySelector('.admin-toolbar-iframe')
    ?.remove()

  // make sure the scripts are executed
  Array.from(
    $iframe.contentWindow?.document.querySelectorAll('script') ?? [],
  ).forEach((oldScriptEl: HTMLElement) => {
    const newScriptEl = document.createElement('script')

    Array.from(oldScriptEl.attributes).forEach((attr) => {
      newScriptEl.setAttribute(attr.name, attr.value)
    })

    const scriptText = document.createTextNode(oldScriptEl.innerHTML)
    newScriptEl.appendChild(scriptText)

    oldScriptEl.parentNode?.replaceChild(newScriptEl, oldScriptEl)
  })

  document.body.childNodes.forEach(($elm: any) => {
    if ($elm.tagName === 'IFRAME') return
    $elm.remove()
  })

  return $iframe
}
