import { defineStore } from 'pinia'

export type TAppStore = {
  stage: 'preview' | 'production'
}

export const useAppStore = defineStore('app', () => {
  const url = useRequestURL()

  return {
    get stage() {
      const hostname = url.hostname
      return hostname.includes('preview') ? 'preview' : 'production'
    },
  } as TAppStore
})
