import validate from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/builds/buzzbrothers/skeleton/nuxt-skeleton/middleware/redirect.global.ts";
import manifest_45route_45rule from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-global-dato": () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/middleware/redirect.global.dato.ts")
}