import { gsap as __gsap } from 'gsap'
import { ScrollTrigger as __ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin as __MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { SplitText as __SplitText } from './plugins/SplitText'

export default function __initGsap(): {
  __gsap: typeof __gsap
  __ScrollTrigger: typeof __ScrollTrigger
  __SplitText: typeof __SplitText
} {
  // register plugins
  __gsap.registerPlugin(__ScrollTrigger, __SplitText, __MotionPathPlugin)

  // Fix the ticker to 60fps
  __gsap.ticker.fps(60)

  // set lag smoothing
  __gsap.ticker.lagSmoothing(0)

  // return the instance
  return { __gsap, __ScrollTrigger, __SplitText }
}
