import './lenis.css'
import __lenis from 'lenis'
import { __isInIframe } from '@lotsof/sugar/is'
import { ELenisEvents } from '~/lib/lenis/lenis.enum.js'

export default function init(): __lenis | undefined {
  const { $gsap, $scrollTrigger } = useNuxtApp()

  if (__isInIframe()) {
    return
  }

  // create a lenis instance
  const lenis = new __lenis()

  // Update the lenis RAF with the GSAP ticker
  $gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
  })

  // Synchro with ScrollTrigger
  lenis.on('scroll', () => {
    $scrollTrigger.update()
  })

  // Force the lenis to scroll to the top
  lenis.scrollTo(0, { immediate: true })

  // Events
  document.addEventListener(ELenisEvents.SCROLL_LOCK, () => {
    lenis.stop()
  })

  document.addEventListener(ELenisEvents.SCROLL_UNLOCK, () => {
    lenis.start()
  })

  document.addEventListener(ELenisEvents.SCROLL_TO, (event) => {
    const { detail } = event as CustomEvent
    lenis.scrollTo(detail?.target ?? 0, {
      immediate: detail.immediate ?? false,
      force: true,
    })
  })

  return lenis
}
