import { reactive } from 'vue'

// No transition to start, set default to true.
const pageTransitionState = reactive({
  pageTransitionComplete: false,
})

export const usePageTransition = () => {
  const togglePageTransitionComplete = (value: any) => {
    pageTransitionState.pageTransitionComplete = value
  }

  return {
    pageTransitionState,
    togglePageTransitionComplete,
  }
}
