export default defineNuxtRouteMiddleware(async (to: any) => {
  //   // check if the redirects are enabled
  //   if (!__projectConfig.redirects.enabled) {
  //     return;
  //   }
  //   // starting destination if the path itself
  //   let destination: string | undefined;
  //   // use the correct model to get the potential redirect
  //   // of the path
  //   switch (__projectConfig.cms) {
  //     case 'dato':
  //       destination = await __models.redirect.getRedirectDestination(to.path);
  //       break;
  //     case 'strapi':
  //       // @TODO            integrate the strapi model
  //       break;
  //   }
  //   // if the destination is different from the current path, redirect
  //   if (destination) {
  //     return navigateTo(destination, { redirectCode: 301 });
  //   }
})
