import revive_payload_client_4sVQNw7RlN from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/buzzbrothers/skeleton/nuxt-skeleton/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jozdw60ZsE from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import titles_dw2T9lEw4h from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import i18dynamicBaseUrl_txzAPws8Op from "/builds/buzzbrothers/skeleton/nuxt-skeleton/plugins/i18dynamicBaseUrl.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_Jozdw60ZsE,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  i18dynamicBaseUrl_txzAPws8Op
]