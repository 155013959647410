import { default as presetscal6h7rwXsMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/_/presets.vue?macro=true";
import { default as _91slug_93hLi4EZUtPAMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue?macro=true";
import { default as _91slug_93SYQK5M1prgMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/event/[slug].vue?macro=true";
import { default as indexoOh6AkBqmDMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/index.vue?macro=true";
import { default as searchrRI97c3qxwMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/search.vue?macro=true";
import { default as _91slug_93vfHCi0sTJUMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/service/[slug].vue?macro=true";
import { default as _91slug_93J0nGMyUaF0Meta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/story/[slug].vue?macro=true";
import { default as component_45stubvCsOzZ3XFUMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubvCsOzZ3XFU } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "_-presets___fr",
    path: "/fr/_/presets",
    meta: presetscal6h7rwXsMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/_/presets.vue")
  },
  {
    name: "_-presets___en",
    path: "/_/presets",
    meta: presetscal6h7rwXsMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/_/presets.vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug()",
    meta: _91slug_93hLi4EZUtPAMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug()",
    meta: _91slug_93hLi4EZUtPAMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue")
  },
  {
    name: "event-slug___fr",
    path: "/fr/event/:slug()",
    meta: _91slug_93SYQK5M1prgMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/event/[slug].vue")
  },
  {
    name: "event-slug___en",
    path: "/event/:slug()",
    meta: _91slug_93SYQK5M1prgMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/event/[slug].vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexoOh6AkBqmDMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexoOh6AkBqmDMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/index.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searchrRI97c3qxwMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: searchrRI97c3qxwMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/search.vue")
  },
  {
    name: "service-slug___fr",
    path: "/fr/service/:slug()",
    meta: _91slug_93vfHCi0sTJUMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/service/[slug].vue")
  },
  {
    name: "service-slug___en",
    path: "/service/:slug()",
    meta: _91slug_93vfHCi0sTJUMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/service/[slug].vue")
  },
  {
    name: "story-slug___fr",
    path: "/fr/story/:slug()",
    meta: _91slug_93J0nGMyUaF0Meta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/story/[slug].vue")
  },
  {
    name: "story-slug___en",
    path: "/story/:slug()",
    meta: _91slug_93J0nGMyUaF0Meta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/story/[slug].vue")
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  }
]