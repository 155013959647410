<template></template>
<script lang="ts" setup>
import * as Sentry from '@sentry/nuxt'

const runtimeConfig = useRuntimeConfig()

if (runtimeConfig.public.environment === 'development') {
  console.log('Sentry DSN:', runtimeConfig.public.sentryDsn)
  console.log('Sentry disabled in development')
}

if (
  runtimeConfig.public.sentryDsn &&
  runtimeConfig.public.environment === 'production'
) {
  Sentry.init({
    dsn: runtimeConfig.public.sentryDsn as string,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}
</script>
