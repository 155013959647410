const transitionMap: Record<string, any> = {
  bubble: defineAsyncComponent(
    () =>
      import(
        '~/components/transitions/pageTransition/transition/bubble/bubble.vue'
      ),
  ),
  fade: defineAsyncComponent(
    () =>
      import(
        '~/components/transitions/pageTransition/transition/fade/fade.vue'
      ),
  ),
  none: defineAsyncComponent(
    () => import('~/components/transitions/pageTransition/transition/none.vue'),
  ),
  simple: defineAsyncComponent(
    () =>
      import(
        '~/components/transitions/pageTransition/transition/simple/simple.vue'
      ),
  ),
}

export default transitionMap
